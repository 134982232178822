import React from 'react';
import PropTypes from 'prop-types';
import {Link, graphql} from 'gatsby';

import Layout from '../components/Layout';
import BlogRoll from '../components/BlogRoll';
import Content, {HTMLContent} from '../components/Content';

export const IndexPageTemplate = ({
  image,
  heading,
  subheading,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <div>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundPosition: `top left`,
          backgroundAttachment: `fixed`,
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '150px',
            lineHeight: '1',
            justifyContent: 'space-around',
            alignItems: 'left',
            flexDirection: 'column',
          }}
        >
          <h1
            className="has-text-weight-bold has-text-centered is-size-3-mobile
            is-size-2-tablet is-size-1-widescreen"
            style={{
              boxShadow:
              'rgb(38, 74, 99) 0.5rem 0px 0px, rgb(38, 74, 99) -0.5rem 0px 0px',
              backgroundColor: 'rgb(38, 74, 99)',
              color: 'white',
              lineHeight: '1',
              padding: '0.25em',
            }}
          >
            {heading}
          </h1>
          <h3
            className="has-text-weight-bold has-text-centered is-size-5-mobile
            is-size-5-tablet is-size-4-widescreen"
            style={{
              boxShadow:
              'rgb(38, 74, 99) 0.5rem 0px 0px, rgb(38, 74, 99) -0.5rem 0px 0px',
              backgroundColor: 'rgb(38, 74, 99)',
              color: 'white',
              lineHeight: '1',
              padding: '0.25em',
            }}
          >
            {subheading}
          </h3>
        </div>
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="column is-12">
                    <PageContent className="content" content={content} />
                    <h1 className="">
                    Portfolio
                    </h1>
                    <BlogRoll />
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/blog">
                      Alle Artikelen
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const IndexPage = ({data}) => {
  const {markdownRemark: page} = data;

  return (
    <Layout>
      <IndexPageTemplate
        image={page.frontmatter.image}
        heading={page.frontmatter.heading}
        subheading={page.frontmatter.subheading}
        contentComponent={HTMLContent}
        content={page.html}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
      }
    }
  }
`;
